<template lang="pug">
  .recorder
    button.btn.btn-info(v-if="!recording" @click="startHandler") Empezar a Grabar
    button.btn.btn-info(v-else @click="end") Detener
    video.videoRecorder(v-show="recording" ref="video")
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Recorder",

  computed: {
    ...mapState({
      recording: state => state.recording.status
    })
  },

  mounted() {
    if (window.opener) {
      if (
        window.opener.signaling &&
        window.opener.signaling.finishStopRecording &&
        window.opener.signaling.recordingStarted
      ) {
        window.signaling = {
          startRecording: () => {
            console.log("Starting recording");
            this.startHandler();
          },
          initStopRecording: () =>
            this.end().then(() => window.opener.finishStopRecording())
        };
      }
    }
  },

  methods: {
    ...mapActions({
      start: "recording/start",
      end: "recording/end"
    }),

    async startHandler() {
      await this.start(this.$refs.video);
      if (
        window.opener &&
        window.opener.signaling &&
        window.opener.signaling.recordingStarted
      )
        window.opener.signaling.recordingStarted();
    }
  }
};
</script>

<style scoped>
.recorder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.videoRecorder {
  width: 50vh;
  height: 50vh;
  object-fit: contain;
  object-position: center;
}
</style>
